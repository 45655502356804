import React, { Component } from 'react';

export class Servico extends Component {

    render() {
        return (
            <div>
                <h3>Serviços</h3>
                <p>Serviços destinados ao consumidor.</p>
            </div>
        );
    }

}
