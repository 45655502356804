import React, { Component } from 'react';
import { DataGrid, DataColumn } from './utils/DataGrid';

export default class Medidor extends Component {
    render() {
        return <div className="hdr-content">
            <DataGrid url="/hdr/medidor">
                <DataColumn header="Código" name="codigo" order="desc" />
                <DataColumn header="Criação" name="dataCriacao" />
                <DataColumn header="Série" name="serie" />
                <DataColumn header="Fabricante" name="fabricante" />
                <DataColumn header="Tipo" name="tipo" />
                <DataColumn header="QuantidadeDigito" name="quantidadeDigito" />
                <DataColumn header="Modelo" name="modelo" />
                <DataColumn header="Qn" name="qn" />
                <DataColumn header="Dn" name="dn" />
                <DataColumn header="Situacao" name="situacao" />
            </DataGrid>
        </div>;
    }
}