import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { Remote } from '../Remote';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom mb-3" dark color="primary">
                    <Container>
                        <NavbarBrand tag={Link} to="/">Atendimento Águas v{Remote.version}</NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} to="/">Início</NavLink>
                                </NavItem>
                                <NavItem>
                                    <a href="http://ibipora.cogesan.com.br/portal/SegundaVia.aspx" target="_blank" rel="noopener noreferrer" className="nav-link">
                                        Segunda via
                                    </a>
                                </NavItem>
                                <NavItem>
                                    <a href="http://ibipora.cogesan.com.br/portal/OS.aspx" target="_blank" rel="noopener noreferrer" className="nav-link">
                                        Outros serviços
                                    </a>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/agente/baixa">Agente Conveniado</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
