import React, { Component } from 'react';

export class Segunda extends Component {

    render() {
        return (
            <div>
                <h3>Segunda via</h3>
                <p>Segunda via de faturas.</p>
            </div>
        );
    }

}
