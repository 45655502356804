import React, { Component } from 'react';
import remote from '../Remote';
import { Input } from 'reactstrap';
import { useState } from 'react';

export class DataColumn extends Component {

    constructor(props) {
        super(props);
        this.handleFilterKeyPress = this.handleFilterKeyPress.bind(this);

        this.state = {
            filter: {},
            order: {}
        };
    }

    handleFilterKeyPress(ev) {
        if (ev.key !== 'Enter')
            return;

        this.props.refresh();
    }

    renderFilter() {
        return <input type="text" className={this.props.className}
            disabled={!this.props.filter}
            name={this.name}
            onChange={this.setFilter}
            onKeyPress={this.handleFilterKeyPress} />
    }

    renderHeader() {
        return <span onDoubleClick={this.props.setOrder} data-name={this.props.name}>
            {this.props.header || this.props.name || ''}&nbsp;
            {this.props.order && < i className={'arrow ' + this.props.order} />}
        </span>;
    }

    render() {
        if (this.props.isHeader)
            return this.renderHeader();

        if (this.props.isFilter)
            return this.renderFilter();

        return this.props.row[this.props.name] || '';
    }
}

DataColumn.defaultProps = {
    filter: true,
    className: 'form-control-sm form-control'
};

export class DataGrid extends Component {
    constructor(props) {
        super(props);

        this.renderRows = this.renderRows.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleSetFilter = this.handleSetFilter.bind(this);
        this.handleSetOrder = this.handleSetOrder.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.loading = false;

        this.state = {
            columns: this.props.children.map(t => {
                let cfg = { ...t.props };

                cfg.filter = typeof cfg.fiter === 'undefined' || true;
                cfg.order = typeof cfg.order === 'undefined' ? false : cfg.order;

                cfg.renderContent = cfg.renderContent || function (col, data) {
                    let val = data[col.name || col.header] || '';
                    return (val).toLocaleString('pt-BR');
                }.bind(this);

                cfg.renderHeader = cfg.renderHeader || function (col, callbackOrder, callbackFilter) {
                    return <span>
                        <span className="d-block" onDoubleClick={callbackOrder}>
                            {col.header} <i className={'arrow ' + (col.order || '')} />
                        </span>
                        <Input type="text" bsSize="sm"
                            disabled={!col.filter}
                            name={col.name || col.header}
                            onChange={callbackFilter}
                            onKeyPress={(ev) => ev.charCode === 13 && this.refresh(this.state.filter)}
                        />
                    </span>;
                }.bind(this);

                return cfg;
            }),

            data: { list: [], total: 0 },

            filter: {},

            page: 0
        };
    }

    componentDidMount() {
        this.refresh();
    }

    refresh(filter, order, page) {
        page = filter ? 0 : (typeof page === 'undefined' ? this.state.page : page);
        filter = filter || this.state.filter;
        order = (order || this.state.columns.find(t => t.order === 'asc' || t.order === 'desc') || {});

        if (Number.isNaN(page))
            return;

        this.loading = true;
        remote.get(this.props.url, {
            _page: page,
            _pageSize: this.props.pageSize || 15,
            _orderBy: order.name + ' ' + order.order,
            ...filter
        })
            .then(res => {
                this.loading = false;
                this.setState({ data: res, page });
            })
            .catch(() => this.loading = false);
    }

    resetArray(original, prop, defaultValue, index, indexValue) {
        let arr = [...original];
        for (let i = 0; i < arr.length; i++) {
            if (i === index) {
                let obj = { ...arr[i] };
                obj[prop] = indexValue;
                arr[i] = obj;
                continue;
            }

            if (arr[i][prop] !== defaultValue) {
                let obj = { ...arr[i] };
                obj[prop] = defaultValue;
                arr[i] = obj;
            }
        }

        return arr;
    }

    handleSelect(t, ix) {
        let data = { ...this.state.data };
        data.list = this.resetArray(this.state.data.list, 'selected', false, ix, true);
        this.setState({ data });
    }

    handleHeaderOrder(t, ix) {
        if (this.loading)
            return;

        const val = t.order === 'asc' ? 'desc' : 'asc';
        this.setState({
            columns: this.resetArray(this.state.columns, 'order', false, ix, val)
        });

        this.refresh(false, { name: t.name, order: val });
    }

    handleHeaderFilter(ev) {
        let filter = { ...this.state.filter };
        filter[ev.target.name] = ev.target.value;
        this.setState({ filter });
    }

    renderRows() {
        return this.state.data.list.map((row, i) => {
            return <tr key={i}>
                {this.props.children.map((t, y) => <td key={y}>{React.cloneElement(t, { row })}</td>)}
            </tr>
        });
    }

    handlePage(ev) {
        ev.preventDefault();

        if (this.loading)
            return;

        const page = ev.target.getAttribute('data-page');
        switch (page) {
            case 'first':
                this.refresh(false, false, 0);
                break;

            case 'last':
                this.refresh(false, false, this.getTotalPages() - 1);
                break;

            default:
                const incr = parseInt(page);
                const newpg = this.state.page + incr;
                if (newpg < 0 || newpg >= this.getTotalPages())
                    return;

                this.refresh(false, false, newpg);
                break;
        }
    }

    getTotalPages() {
        var i = this.state.data.total / (this.props.pageSize || 15);
        var floor = Math.floor(i);
        if (i > floor)
            floor++;

        return floor;
    }

    load(params) {
        const defs = {
            filter: {},
            order: {}
        };

        params = Object.assign({}, defs, params);
        this.loading = true;
        remote.get(this.props.url)
            .then(t =>
                this.setState({
                    filter: params.filter,
                    order: params.order,
                    data: t
                }));
    }

    handleSetFilter(ev) {
        let filter = { ...this.state.filter };
        filter[ev.target.name] = ev.target.value;
        this.setState({ filter });
    }

    handleSetOrder(ev) {
        const name = ev.target.getAttribute('data-name');
        console.log(this.state);
        console.log(name);
        this.setState({
            [name]: this.state.order[name] === 'asc' ? 'desc' : 'asc'
        });
    }

    render() {
        const totalpg = this.getTotalPages();

        return [
            <table className="table table-striped table-hover table-sm" key="0">
                <thead>
                    <tr>
                        {this.props.children.map((t, i) => <th key={i}>{React.cloneElement(t, { isHeader: true, setOrder: this.handleSetOrder })}</th>)}
                    </tr>
                    <tr>
                        {this.props.children.map((t, i) => <th key={i}>{React.cloneElement(t, { isFilter: true, setFilter: this.handleSetFilter, refresh: this.refresh })}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {this.renderRows()}
                </tbody>
            </table>,
            <nav key="1">
                <ul className="pagination justify-content-center">
                    <li className="page-item">
                        <a className="page-link" href="#" onClick={this.handlePage} data-page="-1">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    {totalpg > 1 && <li className="page-item"><a className="page-link" href="#" data-page="first" onClick={this.handlePage}>1</a></li>}
                    <li className="page-item disabled"><span className="page-link" href="#">{this.state.page + 1} de {totalpg} páginas</span></li>
                    {totalpg > 1 && <li className="page-item"><a className="page-link" href="#" data-page="last" onClick={this.handlePage}>{totalpg}</a></li>}
                    <li className="page-item">
                        <a className="page-link" href="#" onClick={this.handlePage} data-page="1">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        ];
    }
}


export default DataGrid;