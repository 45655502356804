import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import remote from '../Remote';
import AgenciaApp from '../HdrApp';

export default class Esqueci extends Component {

    constructor(props) {
        super(props);
        this.state = { uid: '' };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

    }

    handleChange(evt) {
        this.setState({
            [evt.target.name]: evt.target.value
        });
    }

    handleSubmit(evt) {
        evt.preventDefault();

        remote.post('/hdr/autenticacao/esqueci', this.state)
            .then(res => AgenciaApp.instance.toast(res.message));
    }

    render() {
        return (
            <Form className="form-entrar" onSubmit={this.handleSubmit}>
                <h3>Esqueci minha senha</h3>
                <p>Informe seu nome de usuário ou celular para receber sua senha.</p>
                <FormGroup>
                    <Label>Usuário</Label>
                    <Input type="text" name="uid" required value={this.state.uid} onChange={this.handleChange} />
                </FormGroup>

                <FormGroup>
                    <Button type="submit" color="primary" className="btn-block">Entrar</Button>
                </FormGroup>

                <FormGroup>
                    <Link to="/autenticacao/entrar">Clique aqui</Link> para entrar com seu usuário e senha.
                </FormGroup>
            </Form>
        );
    }
}
