import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <Row>
                <Col>
                    <h1>Atendimento Águas</h1>
                    <p>Seja bem-vindo ao Atendimento Águas.</p>

                    <p>Aproveite as facilidades dos serviços on-line Atendimento Águas e resolva tudo de forma prática em seu computador ou smartphone</p>

                    <p>Devido aos riscos de contágio provenientes da pandemia do Coronavírus, disponibilizamos on-line os seguintes serviços:</p>
                    <ul>
                        <li>Consulta de débitos</li>
                        <li>Segunda via de faturas</li>
                        <li>Extrato financeiro</li>
                        <li>Solicitação de religação</li>
                        <li>Solicitação de desligamento</li>
                        <li>Solicitação de outros serviços</li>
                        <li>Consulta de serviços</li>
                    </ul>
                </Col>
                <Col md="4">
                    <h2>Aplicativo Atendimento Águas</h2>
                    <p>Sua fatura de água fácil e rápida.</p>
                    <ul>
                        <li>Instale agora a partir do <a href="https://play.google.com/store/apps/details?id=com.atendimentoaguas" target="_blank">Google Play</a></li>
                        <li>Em breve disponível na Apple Store</li>
                    </ul>
                </Col>
            </Row>
        );
    }
}
