import HdrApp from "./HdrApp";

export class Remote {

    static version = '2.1.4';

    static defaultMessage = 'Ocorreu um erro. Esperamos ter resolvido isso até ' +
        'você tentar novamente.';

    _subscribers = [];
    _subscriberId = 0;
    _user = null;

    async get(url, params, showError) {
        if (showError === undefined)
            showError = true;

        const target = new URL(url, window.location.href);
        if (params)
            Object.keys(params).forEach(t => target.searchParams.append(t, params[t]));

        return this.getPromise(await fetch(target, {
            headers: this.getHeaders()
        }), showError);
    }

    async post(url, body, showError) {
        if (showError === undefined)
            showError = true;

        return this.getPromise(await fetch(url, {
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify(body)
        }), showError);
    }

    async getPromise(res, showError) {
        let content = await res.text();
        try {
            content = JSON.parse(content);
        } catch {
            console.log(content);
        }

        return new Promise((success, error) => {
            if (res.ok) {
                success(content);
                return;
            }

            if (res.status === 401) {
                this.logout();
                content = { message: 'Sua sessão expirou ou você foi deslogado. Por favor entre novamente.'};
            }

            if (showError)
                HdrApp.instance.toast(content.message || Remote.defaultMessage);

            error(content);
        });
    }

    getHeaders() {
        const res = { 'Content-Type': 'application/json' };
        const user = this.getUser();
        if (user)
            res['Authorization'] = `Bearer ${user.token}`;

        res['ClientVersion'] = Remote.version;
        return res;
    }

    isAuthenticated() {
        return !!this.getUser();
    }

    getUser() {
        return this._user || false;
    }

    subscribe(callback) {
        const id = this._subscriberId++;
        this._subscribers.push({ id, callback });
        return id;
    }

    unsubscribe(id) {
        const ix = this._subscribers.findIndex(t => t.id === id);
        if (ix < 0)
            return;

        this._subscribers.splice(ix, 1);
    }

    notifySubscribers(state) {
        this._subscribers.map(t => t.callback(state));
    }

    login(obj) {
        this._user = obj;
        this.notifySubscribers(obj);
    }

    logout() {
        this._user = null;
        this.notifySubscribers(false);
    }
}

const remote = new Remote();

export default remote;
