import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';
import remote from './Remote';

export default class PrivateRoute extends Component {
    constructor(props) {
        super(props);

        this.authenticationChange = this.authenticationChange.bind(this);
        this.state = { authenticated: remote.isAuthenticated() };
    }

    componentDidMount() {
        this._subscription = remote.subscribe(this.authenticationChange);
    }

    componentWillUnmount() {
        remote.unsubscribe(this._subscription);
    }

    authenticationChange(user) {
        this.setState({ authenticated: !!user });
    }

    render() {
        if (!this.state.authenticated) {
            const url = new URL(window.location.href);
            const redir = `/autenticacao/entrar?returnUrl=${encodeURI(url.pathname)}`;
            return <Redirect to={redir} />
        }

        return <Route {...this.props} />
    }
}
