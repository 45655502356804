import React from 'react';
import './css/bootstrap/bootstrap.min.css';
import HdrApp from './hdr/HdrApp';
import AgenciaApp from './agencia/AgenciaApp';

function renderApp(host) {
    switch (host) {
        case 'www.atendimentoaguas.com.br':
        case 'atendimentoaguas.com.br':
            document.title = 'Atendimento Águas - Cogesan';
            return <AgenciaApp />;

        case 'app.hidroreader.com.br':
            document.title = 'HidroReader';
            return <HdrApp />;

        default:
            document.title = 'Cogesan';
            return <AgenciaApp />;
    }
}

function App() {
    const host = window.location.hostname;
    return renderApp(host);
}

export default App;
