import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Toast, ToastBody, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import PrivateRoute from './PrivateRoute';
import Entrar from './autenticacao/Entrar';
import Esqueci from './autenticacao/Esqueci';
import { Baixa } from './agente/Baixa';
import { Lote } from './agente/Lote';
import { Servico } from './publico/Servico';
import { Segunda } from './publico/Segunda';
import { BrowserRouter } from 'react-router-dom';

import './custom.css'


export default class AgenciaApp extends Component {
    static displayName = AgenciaApp.name;
    static instance = null;

    constructor(props) {
        super(props);
        AgenciaApp.instance = this;
        this.toast = this.toast.bind(this);
        this.modal = this.modal.bind(this);
        this.handleModal = this.handleModal.bind(this);

        this.state = {
            toast: { show: false, text: null },
            modal: { show: false, text: null, callback: null }
        };
    }

    toast(text) {
        this.setState({ toast: { show: true, text } });
        setTimeout(() => {
            this.setState({ toast: { show: false } });
        }, 3500);
    }

    modal(text, callback) {
        this.setState({ modal: { show: true, text, callback } });
    }

    handleModal(res) {
        if (this.state.modal.callback)
            this.state.modal.callback(res);

        this.setState({ modal: { show: false, callback: null } });
    }

    render() {
        const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

        return (
            <BrowserRouter basename={baseUrl}>
                <Layout>
                    <Toast isOpen={this.state.toast.show} className="toast-message bg-info text-light">
                        <ToastBody>
                            {this.state.toast.text}
                        </ToastBody>
                    </Toast>

                    <Modal isOpen={this.state.modal.show} toggle={this.toggle}>
                        <ModalHeader>Atendimento Águas</ModalHeader>
                        <ModalBody dangerouslySetInnerHTML={{ __html: this.state.modal.text }} />
                        <ModalFooter>
                            <Button color="primary" onClick={() => this.handleModal(true)}>Confirmar</Button>
                            <Button onClick={() => this.handleModal(false)}>Cancelar</Button>
                        </ModalFooter>
                    </Modal>

                    <Switch>
                        <Route exact path='/' component={Home} />
                        <Route path='/autenticacao/entrar' component={Entrar} />
                        <Route path='/autenticacao/esqueci' component={Esqueci} />

                        <PrivateRoute path='/agente/baixa' component={Baixa} />
                        <PrivateRoute path='/agente/lote' component={Lote} />

                        <Route path='/publico/servico' component={Servico} />
                        <Route path='/publico/segunda' component={Segunda} />
                    </Switch>
                </Layout>
            </BrowserRouter>
        );
    }
}
