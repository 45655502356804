import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import remote from '../Remote';

export default class Entrar extends Component {

    constructor(props) {
        super(props);
        this.state = { uid: '', pwd: '' };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

    }

    handleChange(evt) {
        this.setState({
            [evt.target.name]: evt.target.value
        });
    }

    handleSubmit(evt) {
        evt.preventDefault();

        remote.post('/agencia/autenticacao/entrar', this.state)
            .then(res => {
                remote.login(res);

                const url = new URL(window.location.href);
                const redir = url.searchParams.get('returnUrl') || '/';
                this.props.history.push(redir); // window.location.replace(redir);
            });
    }

    render() {
        return (
            <Form className="form-entrar" onSubmit={this.handleSubmit}>
                <h3>Autenticação</h3>
                <p>Informe seu usuário e senha para entrar.</p>
                <FormGroup>
                    <Label>Usuário</Label>
                    <Input type="text" name="uid" required value={this.state.uid} onChange={this.handleChange} />
                </FormGroup>

                <FormGroup>
                    <Label>Senha</Label>
                    <Input type="password" name="pwd" required value={this.state.pwd} onChange={this.handleChange} />
                </FormGroup>

                <FormGroup>
                    <Button type="submit" color="primary" className="btn-block">Entrar</Button>
                </FormGroup>

                <FormGroup>
                    Esqueceu sua senha? <Link to="/autenticacao/esqueci">Clique aqui</Link>
                </FormGroup>
            </Form>
        );
    }
}
