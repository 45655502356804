import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import {
    Row, Col, Button, Table, Badge, Modal, ModalBody, ModalFooter,
    ModalHeader, Navbar, NavItem, NavLink, Container
} from 'reactstrap';
import moment from 'moment';
import remote from '../Remote';
import AgenciaApp from '../AgenciaApp';

export class Lote extends Component {

    constructor(props) {
        super(props);
        this.state = { busca: '', lotes: [] };
        this.onChange = this.onChange.bind(this);
        this.atualizar = this.atualizar.bind(this);
        this.encerrar = this.encerrar.bind(this);
        this.enviarEncerramento = this.enviarEncerramento.bind(this);
        this.imprimir = this.imprimir.bind(this);
    }

    componentDidMount() {
        this.atualizar();
    }

    onChange(ev) {
        this.setState({
            busca: ev.target.value,
            documentos: this.state.documentos
        });
    }

    atualizar() {
        this.setState({
            lotes: []
        });

        remote.get('/agencia/lote/listar')
            .then(res => {
                this.setState({ lotes: res });
            });
    }

    enviarEncerramento(lote) {
        remote.post('/agencia/agente/encerrar/' + lote.loteArrecadacaoId)
            .then(() => this.atualizar());
    }

    encerrar(lote) {
        AgenciaApp.instance.modal('Deseja confirmar o encerramento do ' +
            `lote ${lote.codigo}, com recebimento de ${lote.registros} ` +
            `no valor de ${lote.valorTotalEfetivo.toFixed(2).toLocaleString()}? <br/><br/>` +
            'Esta operação não poderá ser desfeita.', t => t && this.enviarEncerramento(lote));
    }

    imprimir(id) {
        remote.get('/agencia/agente/imprimir', { id })
            .then(t => window.open('https://app.cogesan.com.br/Arrecadacao/LoteArrecadacaoWeb.lex/VisualizarAgente?id=' + t.id, '_blank'));
    }

    render() {
        const situacoes = ['', 'Processado', '', 'Pendente', 'Encerrado'];
        const cores = ['', 'Processado', '', 'bg-amarelo', 'bg-azul'];

        return (
            <div>
                <Modal isOpen={this.state.showModal} toggle={this.toggle}>
                    <ModalHeader>Atendimento Águas</ModalHeader>
                    <ModalBody dangerouslySetInnerHTML={{ __html: this.state.msgModal }}>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.okModal}>Confirmar</Button>
                        <Button onClick={this.toggle}>Cancelar</Button>
                    </ModalFooter>
                </Modal>

                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavLink className="text-dark" href="#">{remote.getUser().nome}</NavLink>
                        <div className="d-sm-inline-flex">
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/agente/baixa">Registro de pagamentos</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/agente/lote">Gestão de lotes</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="text-dark" href="#" onClick={() => remote.logout()}>Sair</NavLink>
                                </NavItem>
                            </ul>
                        </div>
                    </Container>
                </Navbar>

                <Row>
                    <Col>
                        <Button onClick={this.atualizar} color="primary">Atualizar</Button>
                        <hr />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Table bordered className="table-docs" size="sm">
                            <thead className="thead-light">
                                <tr>
                                    <th>Lote</th>
                                    <th>Data</th>
                                    <th>Registros</th>
                                    <th>Total (R$)</th>
                                    <th style={{width: '20%'}}></th>
                                </tr>
                            </thead>

                            <tbody>
                                {this.state.lotes.map(it => (

                                    <tr key={it.loteArrecadacaoId} className={cores[it.situacao]}>
                                        <td>
                                            {it.codigo} &nbsp;
                                            <Badge>{situacoes[it.situacao]}</Badge>
                                        </td>
                                        <td className="text-right">{it.dataArquivo ? moment(it.dataArquivo).format('DD/MM/YYYY') : ''}</td>
                                        <td className="text-right">{it.registros}</td>
                                        <td className="text-right">{(it.valorTotalEfetivo || 0).toFixed(2).toLocaleString()}</td>
                                        <td>
                                            <Row>
                                                <Col lg="6">
                                                    <Button size="sm" color="primary" className="btn-block" onClick={() => this.imprimir(it.loteArrecadacaoId)}>Imprimir</Button>
                                                </Col>
                                                <Col lg="6">
                                                    <Button size="sm" color="info" className="btn-block" onClick={() => this.encerrar(it)} disabled={it.situacao !== 3}>Encerrar</Button>
                                                </Col>
                                            </Row>
                                        </td>
                                    </tr>

                                ))}
                                {this.state.lotes.length === 0 &&
                                    <tr>
                                        <td colSpan="5">Carregando...</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        );
    }
}
