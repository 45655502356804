import React, { Component } from 'react';
import { BrowserRouter, Link, Switch, Route } from 'react-router-dom';
import remote, { Remote } from './Remote';
import {
    Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,
    Toast, ToastBody, Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';
import PrivateRoute from './utils/PrivateRoute';
import Entrar from './autenticacao/Entrar';
import Esqueci from './autenticacao/Esqueci';
import Home from './Home';
import Medidor from './Medidor';

import './hdr.css';

export default class HdrApp extends Component {
    constructor(props) {
        super(props);

        HdrApp.instance = this;
        this.toast = this.toast.bind(this);
        this.modal = this.modal.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.togglerClick = this.togglerClick.bind(this);
        this.handleBuscar = this.handleBuscar.bind(this);

        this.state = {
            toast: { show: false, text: null },
            modal: { show: false, text: null, callback: null },
            navOpened: false,
            authenticated: false
        };

        this.authenticationChange = this.authenticationChange.bind(this);
    }

    componentDidMount() {
        this._subscription = remote.subscribe(this.authenticationChange);
    }

    componentWillUnmount() {
        remote.unsubscribe(this._subscription);
    }

    authenticationChange(user) {
        this.setState({ authenticated: !!user });
    }

    toast(text) {
        this.setState({ toast: { show: true, text } });
        setTimeout(() => {
            this.setState({ toast: { show: false } });
        }, 3500);
    }

    modal(text, callback) {
        this.setState({ modal: { show: true, text, callback } });
    }

    handleModal(res) {
        if (this.state.modal.callback)
            this.state.modal.callback(res);

        this.setState({ modal: { show: false, callback: null } });
    }

    togglerClick() {
        this.setState({ navOpened: !this.state.navOpened });
    }

    handleBuscar(ev) {
        this.setState({ buscar: ev.target.value });
    }

    render() {
        const user = remote.getUser() || { isAnonymous: true, menus: [] };

        const menus = user.menus.filter(t => t.text.toLowerCase().indexOf(this.state.buscar || '') > -1);
        return <BrowserRouter>
            <Toast isOpen={this.state.toast.show} className="toast-message bg-info text-light">
                <ToastBody>
                    {this.state.toast.text}
                </ToastBody>
            </Toast>

            <div className="d-flex flex-column" style={{ height: '100%' }}>
                <div className="navbar bg-primary navbar-dark">
                    <Link to="/" className="navbar-brand">HidroReader v{Remote.version}</Link>
                    {!user.isAnonymous &&
                        <NavbarToggler className="d-md-none" onClick={this.togglerClick} />
                    }
                    <Collapse className="d-md-none navbar-nav align-items-stretch" tag="ul" isOpen={this.state.navOpened} navbar>
                        {user.menus.map(t =>
                            <li className="nav-item" key={t.id}>
                                <Link className="nav-link" to={t.link}>{t.text}</Link>
                            </li>)}
                    </Collapse>
                </div>

                <div className="d-flex flex-row flex-grow-1">
                    {!user.isAnonymous &&
                        <div className="d-none d-md-block border-right" style={{ width: 240 }}>
                            <div className="p-2">
                                <input type="search" className="form-control" placeholder="Buscar..." autoComplete="off" onChange={this.handleBuscar} />
                            </div>
                            <ul className="nav flex-column">
                                {menus.map(t =>
                                    <li className="nav-item" key={t.id}>
                                        <Link className="nav-link" to={t.link}>{t.text}</Link>
                                    </li>)}
                            </ul>
                        </div>
                    }
                    <Switch>
                        <PrivateRoute exact path='/' component={Home} />
                        <PrivateRoute exact path='/medidor' component={Medidor} />
                        <Route path='/autenticacao/entrar' component={Entrar} />
                        <Route path='/autenticacao/esqueci' component={Esqueci} />
                    </Switch>
                </div>
            </div>

        </BrowserRouter>;
    }
}