import React, { Component } from 'react';
import { Link, Prompt } from 'react-router-dom'
import {
    Row, Col, Button, FormGroup, Label, Input, InputGroup,
    InputGroupAddon, Table, Badge, Navbar, NavItem, NavLink, Container
} from 'reactstrap';
import moment from 'moment';
import remote from '../Remote';
import AgenciaApp from '../AgenciaApp';

export class Baixa extends Component {

    constructor(props) {
        super(props);
        this.state = { busca: '', documentos: [] };
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onChange = this.onChange.bind(this);
        this.buscar = this.buscar.bind(this);
        this.limpar = this.limpar.bind(this);
        this.confirmar = this.confirmar.bind(this);
        this.registrar = this.registrar.bind(this);
        this.handleSair = this.handleSair.bind(this);
        this.excluir = this.excluir.bind(this);
    }

    componentDidMount() {
        if (!this.busca)
            return;

        this.busca.focus();
    }

    onChange(ev) {
        this.setState({
            busca: ev.target.value,
            documentos: this.state.documentos
        });
    }

    onKeyDown(ev) {
        if (ev.keyCode === 13) {
            this.buscar();
            if (this.busca)
                this.busca.focus();

            return;
        }
    }

    buscar() {
        this.componentDidMount();
        const busca = this.state.busca;

        if (!busca)
            return;

        const existe = this.state.documentos.filter(t => t.original.localeCompare(busca) === 0);
        if (existe.length > 0) {
            this.setState({ busca: '' });
            return;
        }

        this.setState({
            busca: '',
            documentos: [{ busca, original: busca, id: new Date().getTime(), dataVencimento: null }].concat(this.state.documentos)
        });

        remote.post('/agencia/agente/buscar', { busca })
            .then(res => {
                const existe = this.state.documentos.filter(t => {
                    return t.codigo === res.codigo
                        && t.agrupado === res.agrupado;
                });

                if (existe.length > 0) {
                    this.setState({ documentos: this.state.documentos.filter(t => t.original.localeCompare(res.original) !== 0) });
                    return;
                }

                const filter = this.state.documentos.filter(t => {
                    return t.original.localeCompare(res.original) === 0;
                });

                if (filter.length === 0)
                    return;

                filter[0].situacao = res.situacao;
                filter[0].dataVencimento = res.dataVencimento;
                filter[0].erro = res.erro;
                filter[0].codigo = res.codigo;
                filter[0].agrupado = res.agrupado;
                filter[0].uc = res.uc;
                filter[0].valorTotal = res.valorTotal;

                this.setState(this.state);
            })
            .catch(res => {
                const filter = this.state.documentos.filter(t => {
                    return t.original.localeCompare(res.original) === 0;
                });

                if (filter.length === 0)
                    return;

                this.setState({ documentos: this.state.documentos.filter(t => t !== filter[0]) });
            });
    }

    limpar() {
        this.setState({
            busca: '',
            documentos: []
        });
    }

    registrar() {
        if (this.loading)
            return;

        this.loading = true;

        remote.post('/agencia/agente/registrar', this.state.documentos)
            .then(() => {
                this.loading = false;

                AgenciaApp.instance.toast('Pagamentos registrados com sucesso.');
                this.limpar();
            });
    }

    confirmar() {
        if (this.loading)
            return;

        const pendentes = this.state.documentos.filter(t => !t.valorTotal);
        if (this.state.documentos.length === 0 || pendentes.length > 0) {
            AgenciaApp.instance.toast('Aguarde todos os documentos serem processados.');
            return;
        }

        const msg = 'Registrar o pagamento de {0} documento{1} no valor de R$ {2}.<br/><br/>' +
            'Esta operação não poderá ser desfeita.';

        let total = 0.0;
        this.state.documentos.map(t => total += t.valorTotal || 0);

        AgenciaApp.instance.modal(msg
            .replace('{0}', this.state.documentos.length)
            .replace('{1}', this.state.documentos.length > 1 ? 's' : '')
            .replace('{2}', total.toFixed(2).toLocaleString()),
            t => t && this.registrar()
        );
    }

    handleSair() {
        return this.state.documentos.length > 0 ? 'Há pagamentos não registrados. Deseja continuar?' : true;
    }

    excluir(it) {
        this.setState({ documentos: this.state.documentos.filter(t => t !== it) });
    }

    render() {
        const situacoes = ['', 'Aguardando', 'Pendente', 'Contestado', 'Baixado', 'Pago Agente'];

        let total = 0.0;
        this.state.documentos.map(t => total += t.valorTotal || 0);

        return (
            <div>
                <Prompt message={this.handleSair} />

                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavLink className="text-dark" href="#">{remote.getUser().nome}</NavLink>
                        <div className="d-sm-inline-flex">
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/agente/baixa">Registro de pagamentos</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark"  to="/agente/lote">Gestão de lotes</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="text-dark" href="#" onClick={t => remote.logout()}>Sair</NavLink>
                                </NavItem>
                            </ul>
                        </div>
                    </Container>
                </Navbar>

                <Row>
                    <Col lg="5">
                        <FormGroup>
                            <Label for="busca">Código de barras</Label>
                            <InputGroup>
                                <Input id="busca" innerRef={t => this.busca = t} autoComplete="off" onKeyDown={this.onKeyDown} onChange={this.onChange} value={this.state.busca} />
                                <InputGroupAddon addonType="append">
                                    <Button color="primary" onClick={this.buscar}>Buscar</Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col lg="7">
                        <Row>
                            <Col xs="4">
                                <FormGroup>
                                    <Label for="total">Total (R$)</Label>
                                    <Input id="total" className="text-right" value={total.toFixed(2).toLocaleString()} readOnly />
                                </FormGroup>
                            </Col>
                            <Col xs="2">
                                <FormGroup>
                                    <Label for="total">&nbsp;</Label>
                                    <Input id="total" className="text-right" value={this.state.documentos.length} readOnly />
                                </FormGroup>
                            </Col>
                            <Col xs="3">
                                <FormGroup>
                                    <div><Label>&nbsp;</Label></div>
                                    <Button color="success" className="btn-block" onClick={this.confirmar}>Confirmar</Button>
                                </FormGroup>
                            </Col>
                            <Col xs="3">
                                <FormGroup>
                                    <div><Label>&nbsp;</Label></div>
                                    <Button className="btn-block" onClick={this.limpar}>Limpar todos</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Table bordered className="table-docs" size="sm">
                            <thead className="thead-light">
                                <tr>
                                    <th>Documento</th>
                                    <th style={{ width: '20%' }}>UC</th>
                                    <th style={{ width: '20%' }}>Vencimento</th>
                                    <th style={{ width: '20%' }}>Valor (R$)</th>
                                    <th style={{ width: '10%' }}></th>
                                </tr>
                            </thead>

                            <tbody>
                                {this.state.documentos.map(it => (

                                    <tr key={it.id} className={it.situacao > 2 ? 'bg-amarelo' : ''}>
                                        <td>
                                            {it.codigo || it.agrupado || it.busca} &nbsp;
                                            <Badge hidden={it.situacao === 2} color="danger">{situacoes[it.situacao]}</Badge>
                                        </td>
                                        <td className="text-right">{it.uc || ''}</td>
                                        <td className="text-right">{it.dataVencimento ? moment(it.dataVencimento).format('DD/MM/YYYY') : ''}</td>
                                        <td className="text-right">{it.valorTotal ? it.valorTotal.toFixed(2).toLocaleString() : ''}</td>
                                        <td>
                                            <Button size="sm" onClick={() => this.excluir(it)}>&#10005;</Button>
                                        </td>
                                    </tr>

                                ))}
                                {this.state.documentos.length === 0 &&
                                    <tr>
                                        <td colSpan="5">Nenhum registro de pagamento.</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        );
    }
}
